declare let ga: (
  action: string,
  type: string,
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
) => void;

export type gaEventData = {
  category: string;
  action: string;
  label: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export function trackingNameById(trackingId?: string | null): string | null {
  try {
    const windowGa = (window as any).ga;
    if (
      !trackingId ||
      typeof windowGa === 'undefined' ||
      typeof windowGa.getAll !== 'function'
    ) {
      return null;
    }

    return (
      windowGa
        .getAll()
        .find((tracking: any) => tracking.get('trackingId') === trackingId)
        ?.get('name') ?? null
    );
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function sendGAEvent(gaEventData: gaEventData): void {
  if (typeof ga !== 'function') {
    console.warn('Google Analytics not set up (ga is not a function).');
    console.info(gaEventData);
    return;
  }

  try {
    const trackingName = process.env.VUE_APP_GA_TM_TRACKING_NAME
      ? process.env.VUE_APP_GA_TM_TRACKING_NAME
      : trackingNameById(process.env.VUE_APP_GA_TM_TRACKING_ID);
    ga(
      `${trackingName ? trackingName + '.' : ''}send`,
      'event',
      gaEventData.category,
      gaEventData.action,
      gaEventData.label,
    );
  } catch (e) {
    console.error(e);
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
