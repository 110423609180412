import { AssistantContext } from '../../config/context';
import { StepGroupId, StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormField,
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import i18n from '../../plugins/i18n';
import { getStepContext } from '../context';
import { loadProductsByIds } from '../greenportal/load-products';
import { getStep } from '../steps/helper';

// export function getStepValue(id: StepId, value: any): string {
//   if (!value || !id) return '';

//   const step = getStep(id, []);

//   const fields: Array<FormField> = step?.fields?.() || [];

//   if (fields) {
//     return (
//       fields
//         // filter out form field that dont match the visiblity condition
//         .filter(field =>
//           field.condition !== undefined ? field.condition(value) : true
//         )
//         // filter out all field that dont have a value or value length is 0
//         .filter(field => {
//           const fieldValue = value[field.name];

//           return (
//             fieldValue !== undefined &&
//             fieldValue !== null &&
//             ((typeof fieldValue === 'string' &&
//               (fieldValue as string).length > 0) ||
//               (typeof fieldValue === 'object' && fieldValue.length > 0) ||
//               typeof fieldValue === 'boolean')
//           );
//         })
//         .map(field => {
//           let fieldValue = '';
//           if (field.type === FormFieldType.FileUpload) {
//             fieldValue = (Array.isArray(value[field.name])
//               ? value[field.name]
//               : [value[field.name]]
//             )
//               .map((file: File) => {
//                 return file.name;
//               })
//               .join(', ');
//           } else if (field.type === FormFieldType.MultipleChoice) {
//             fieldValue = (value[field.name] as Array<string>)
//               .map(optionValue => {
//                 if (
//                   (field.config as FormMultipleChoiceConfig).options
//                     .map(o => o.value)
//                     .includes(optionValue)
//                 ) {
//                   return i18n.t(
//                     `${id}.formFields.${field.name}.options.${optionValue}.label`
//                   );
//                 }
//                 return optionValue;
//               })
//               .join(', ');
//           } else if (field.type === FormFieldType.Select) {
//             fieldValue = i18n.t(
//               `${id}.formFields.${field.name}.options.${
//                 value[field.name]
//               }.label`
//             ) as string;
//           } else if (
//             field.type === FormFieldType.Switch ||
//             field.type === FormFieldType.Checkbox
//           ) {
//             fieldValue = ((value[field.name] as boolean)
//               ? i18n.t(`switch.yes`)
//               : i18n.t(`switch.no`)) as string;
//           } else {
//             fieldValue = value[field.name] as string;
//           }

//           let fieldLabel = '';

//           if (!field.displayOutput || field.displayOutput === 'default') {
//             fieldLabel = `**${stripHtml(
//               i18n.t(`${id}.formFields.${field.name}.label`) as string
//             )}**`;

//             if (!fieldLabel.endsWith('?') || fieldLabel.endsWith(':')) {
//               fieldLabel = fieldLabel + ':';
//             }

//             if (fieldLabel.length > 29) {
//               fieldLabel = fieldLabel + '\n';
//             }
//           }

//           return (fields.length > 1 ? `${fieldLabel} ` : '') + fieldValue;
//         })
//         .join('\n ')
//     );
//   }
//   return '';
// }

export const getProductIdFromUrl = () => {
  const DEFAULT_PRODUCT_ID = process.env.VUE_APP_SHOP_PRODUCT_ID;
  const url = new URL(window.location.href);

  return url.searchParams.get('product-id') ?? DEFAULT_PRODUCT_ID;
};

function getStepTitle(stepId: StepId | string) {
  return i18n.t(`${stepId}.title`);
}

function getStepFieldLabel(stepId: StepId | string, fieldName: string) {
  return i18n.t(`${stepId}.formFields.${fieldName}.label`);
}

function getFormattedTextFieldValue(fieldValue: any): string {
  return ((fieldValue as string) || '').trim();
}

function getFormattedMultipleChoiceValue(
  field: FormField,
  fieldValue: string[],
  step: StepDefinition,
): string {
  return fieldValue
    .map(optionValue => {
      if (
        (field.config as FormMultipleChoiceConfig).options
          .map(o => o.value)
          .includes(optionValue)
      ) {
        return i18n.t(
          `${step.id}.formFields.${field.name}.options.${optionValue}.label`,
        );
      }
      return optionValue;
    })
    .join(', ');
}

export function getFormattedFieldValue(
  field: FormField,
  stepContext: Record<string, any>,
  step: StepDefinition,
): string {
  const fieldValue = stepContext[field.name];

  if (fieldValue === '') {
    return '';
  }

  if (field.outputFormatter && typeof field.outputFormatter === 'function') {
    return field.outputFormatter(fieldValue);
  }

  let outputString = '';

  if (field.outputFormatter !== 'value') {
    if (step.id === 'carAdmission' || step.id === 'rangeEstimation') {
      outputString +=
        `**${getStepFieldLabel(step.id, field.name)}** ` + '</br>';
    } else {
      outputString += `**${getStepFieldLabel(step.id, field.name)}** `;
    }
  }

  if (
    field.outputValueFormatter &&
    typeof field.outputValueFormatter === 'function'
  ) {
    return outputString + field.outputValueFormatter(fieldValue);
  }

  switch (field.type) {
    case FormFieldType.TextField:
      outputString += getFormattedTextFieldValue(fieldValue);
      break;
    case FormFieldType.MultipleChoice:
      outputString += getFormattedMultipleChoiceValue(field, fieldValue, step);
      break;
    case FormFieldType.Switch:
    case FormFieldType.Checkbox:
      outputString += fieldValue ? i18n.t('switch.yes') : i18n.t('switch.no');
      break;
  }

  return outputString;
}

export function getFormattedStepValues(
  context: AssistantContext,
  step: StepDefinition,
  repeatIndex?: number,
): string {
  const stepContext = getStepContext(context, step, repeatIndex).value;

  if (!stepContext) {
    return '';
  }

  if (step.outputFormatter) {
    return step.outputFormatter(stepContext);
  }

  if (!step.fields || step.fields.length === 0) {
    return '';
  }

  const fieldValues = step.fields?.map(field =>
    getFormattedFieldValue(field, stepContext, step),
  );

  const fieldValuesWithoutEmptyLines = fieldValues.filter(e => e);

  return fieldValuesWithoutEmptyLines.join('<br />');
}

export function getFormattedStepGroupValues(
  context: AssistantContext,
  groupId: StepGroupId | string,
  steps: StepDefinition[],
) {
  const groupSteps = steps.filter(
    step => step.groupId && step.groupId === groupId,
  );
  const groupItemsCount = (context[groupId] as Array<any>).length || 0;

  return [...Array(groupItemsCount)].map((_, i) => {
    return groupSteps
      .map(step => {
        let outputString = '';

        if (groupSteps.length > 1) {
          outputString += `## ${getStepTitle(step.id)}  `;
        }

        outputString += getFormattedStepValues(context, step, i);

        return outputString;
      })
      .join('<br /><br />');
  });
}

export async function getMailBody(
  steps: StepDefinition[],
  context: AssistantContext,
): Promise<any> {
  const items: Array<{
    id: string | StepId | StepGroupId;
    type: 'step' | 'stepGroup';
  }> = [];

  steps
    .filter(step => step.fields && step.fields.length > 0)
    .forEach(step => {
      if (step.groupId) {
        if (
          items.findIndex(
            item => item.id === step.groupId && item.type === 'stepGroup',
          ) === -1
        ) {
          items.push({
            id: step.groupId,
            type: 'stepGroup',
          });
        }
      } else {
        if (
          items.findIndex(
            item => item.id === step.id && item.type === 'step',
          ) === -1
        ) {
          items.push({
            id: step.id,
            type: 'step',
          });
        }
      }
    });

  const formattedItems = items.map(item => {
    if (item.type === 'stepGroup') {
      const groupValues = getFormattedStepGroupValues(context, item.id, steps);
      return groupValues.map((groupValue, k) => ({
        label: i18n.t('groups.' + item.id) + ' ' + (k + 1),
        value: groupValue,
      }));
    } else {
      const step = getStep(item.id, steps);
      return {
        label: i18n.t(item.id + '.title'),
        value: step ? getFormattedStepValues(context, step) : '',
      };
    }
  });

  const productId = getProductIdFromUrl();

  const productDetails = await loadProductsByIds([productId]);

  const productName =
    productDetails?.[0]?.name || productDetails?.[0]?.translated?.name || '';

  const productOptions = productDetails?.[0]?.options?.map(
    option => option?.translated?.name,
  );

  const productVariant = productOptions?.join(' | ') || '';

  const productNumber = productDetails?.[0]?.productNumber || '';

  formattedItems.unshift({
    label: 'Artikelnummer',
    value: productNumber,
  });

  formattedItems.unshift({
    label: 'Variante',
    value: productVariant,
  });

  formattedItems.unshift({
    label: 'Produkt',
    value: productName,
  });

  return formattedItems;
}

export function productRequestComment(mailBody: any): string {
  let comment = '';
  mailBody.forEach(element => {
    comment += `${element.label}\n${'='.repeat(element.label.length)}\n`;
    comment += `${element.value
      .split('<br />')
      .join('\n')
      .replace(/\*\*/g, '')}\n`;
    comment += `\n`;
  });
  return comment;
}

export function productRequestData(
  mailBody: any,
  context: AssistantContext,
): object {
  const productId = getProductIdFromUrl();

  return {
    city: context.customerDataCollection.city,
    comment: productRequestComment(mailBody),
    email: context.customerDataCollection.email,
    firstName: context.customerDataCollection.prename,
    lastName: context.customerDataCollection.lastname,
    productId,
    productUrl: `${process.env.VUE_APP_SHOP_HOST}/detail/${productId}`,
    salutationDisplayName: 'Keine Angabe',
    sendMail: true,
    street:
      context.customerDataCollection.street +
      ' ' +
      context.customerDataCollection.houseNumber,
    zipCode: context.customerDataCollection.zipcode,
  };
}
