








































import { defineComponent, ref } from '@vue/composition-api';
import GcButton from '../components/primitives/GcButton.vue';
import GcAlert from '../components/primitives/GcAlert.vue';
import GcRow from '../components/primitives/GcRow.vue';
import GcCol from '../components/primitives/GcCol.vue';

export default defineComponent({
  components: {
    GcAlert,
    GcButton,
    GcCol,
    GcRow,
  },
  props: {
    isSubmit: {
      default: false,
      type: Boolean,
    },
    isSubmitting: {
      default: false,
      type: Boolean,
    },
    showBackButton: {
      default: true,
      type: Boolean,
    },
    submitError: {
      default: false,
      type: [Boolean, String],
    },
    submitting: {
      default: false,
      type: Boolean,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup(_, { emit }) {
    const errorClass = ref('mt-10');

    function next() {
      emit('next');
    }

    function prev() {
      emit('prev');
    }

    function submit() {
      emit('submit');
    }

    return {
      errorClass,
      next,
      prev,
      submit,
    };
  },
});
