import customerDataCollection from '../steps/customerDataCollection';
import counterNumber from '../steps/counterNumber';
import isAlreadyStawagCustomer from '../steps/isAlreadyStawagCustomer';
import carAdmission from '../steps/carAdmission';
import rangeEstimation from '../steps/rangeEstimation';
import vehicleRegistrationDocument from '../steps/vehicleRegistrationDocument';
import summary from '../steps/summary';
import done from '../steps/done';

const steps = [
  isAlreadyStawagCustomer,
  customerDataCollection,
  counterNumber,
  carAdmission,
  rangeEstimation,
  vehicleRegistrationDocument,
  summary,
  done,
];

export default steps;
