import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Summary.vue';

const config: StepDefinition = {
  component,
  fields: [],
  id: StepId.summary,
};

export default config;
