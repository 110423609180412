























import GcForm from '../../components/primitives/GcForm.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import completedSteps from '@/lib/completed-steps';
import { defineComponent } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import FormField from '../../components/FormField.vue';
export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const formData = getStepContext(props.assistantContext, props.step!);
    const { onNext, onPrev } = useBaseTransitions(emit);

    return {
      completedSteps,
      formData,
      onNext,
      onPrev,
    };
  },
});
