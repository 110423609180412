import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import { FormFieldType } from '../../consts/input-types';
import component from './IsAlreadyStawagCustomer.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
      },
      name: 'isAlreadyStawagCustomer',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      name: 'contractId',
      required: true,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.isAlreadyStawagCustomer,
};

export default config;
