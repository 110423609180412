import { ActionTypes, Machine, interpret } from 'xstate';
import { computed, reactive, ref, watch } from '@vue/composition-api';
import {
  clearStateFromLocalStorage,
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from '../storage/local-storage';
import { NEXT } from '../../consts/assistant-state-events';
import { buildStates } from './builder';
import { getInitialStep, getStep, getStepIndex } from '../steps/helper';
import { createContextFromSteps } from '../context';
import { StepDefinition } from '../../interfaces/step-definition';
import { AssistantContext } from '../../config/context';
import { AssistantMachine } from '../../interfaces/assistant';

// use local storage if env variable is set to "true"
const useLocalStorage = process.env.VUE_APP_USE_LOCALSTORAGE == 'true';

export function getFilesFromContext(context: AssistantContext): File[] {
  function searchForFiles(tree: { [key: string]: any }) {
    let files: File[] = [];
    Object.values(tree).forEach(o => {
      if (Array.isArray(o)) {
        o.forEach(a => {
          if (a instanceof File) {
            files.push(a);
          } else {
            files = files.concat(searchForFiles(a));
          }
        });
      } else if (typeof o === 'object') {
        files = files.concat(searchForFiles(o));
      }
    });

    return files;
  }

  return searchForFiles(context);
}

export function useAssistantMachine(steps: StepDefinition[]): AssistantMachine {
  const context = reactive(createContextFromSteps(steps));

  const assistantMachine = Machine({
    id: 'assistant',
    initial: getInitialStep(steps),
    states: buildStates(steps, context),
  });

  const service = interpret(assistantMachine).start();
  const currentState = ref(service.state.value as string);

  const currentStepIndex = computed(() => {
    return getStepIndex(currentState.value, steps) + 1 || 1;
  });

  const currentStep = computed(() => {
    return getStep(currentState.value, steps);
  });

  const final = ref(false);

  function send(transition: string, payload?: any) {
    service.send(transition, payload);
  }

  // set current state variable on transition and save state to local storage
  service.onTransition(async (newState, event) => {
    currentState.value = newState.value as string;

    if (useLocalStorage && event.type !== ActionTypes.Init && currentStep.value) {
      saveStateToLocalStorage(context, currentStep.value.id);
    }
  });

  // watch context and save state every time it changes
  watch(context, value => {
    if(useLocalStorage) {
      saveStateToLocalStorage(value, currentStep.value?.id || '');
    }
  });

  service.onDone(() => {
    final.value = true;

    if(useLocalStorage) {
      setTimeout(clearStateFromLocalStorage, 100);
    }
  });

  function setContext(newContext: any) {
    Object.entries(newContext).forEach(([key, value]) => {
      if (Object.keys(context).includes(key))
        (context as any)[key as string] = value;
    });
  }

  const hasPersistedState = useLocalStorage ? ref(loadStateFromLocalStorage() !== null) : ref(false);

  function restoreState(): void {
    if(useLocalStorage) {
      const persistedState = loadStateFromLocalStorage();
      if (persistedState?.context) {
        setContext(persistedState.context);

        let i = 0;
        const stepId = persistedState.step || '';
        let reachedState = currentState.value === stepId;
        while (!reachedState && i < steps.length) {
          const nextState = service.send(NEXT);

          if (!nextState) break;
          if (nextState.value === stepId) reachedState = true;

          i++;
        }
      }
    }
}

  return {
    context,
    currentState,
    currentStep,
    currentStepIndex,
    final,
    hasPersistedState,
    restoreState,
    send,
  };
}
