export default {
  addGroupItem: '{groupName} hinzufügen',
  button: {
    navigation: {
      back: 'Zurück',
      next: 'Weiter',
      skip: 'Frage überspringen',
    },
    request: {
      submit: 'Anfrage absenden',
    },
    stage: {
      add: 'Weiteres Eis hinzufügen',
    },
    upload: {
      directory: 'Ordner hochladen',
      files: 'Dateien hochladen',
    },
  },
  cancel: 'Abbrechen',
  close: 'Schließen',
  contact: {
    city: 'Stadt',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    zipcode: 'Postleitzahl',
  },
  continue: 'Fortsetzen',
  customOption: 'Sonstiges',
  dropFiles: 'Und jetzt einfach loslassen!',
  edit: 'Bearbeiten',
  foundStateToRestore:
    'Es wurde ein gespeicherter Fortschritt für diesen Assistenten gefunden. Möchten Sie diesen fortsetzen?',
  goToContactForm: 'Zum Kontaktformular',
  message: {
    error: {
      body: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    },
    success: {
      body: 'Wir werden uns sobald wie möglich bei Ihnen melden.',
      title: 'Vielen Dank für Ihre Anfrage!',
    },
  },
  remove: 'Entfernen',
  skipMessage:
    'Sie fühlen sich unsicher? Kein Problem! Einer unserer Experten erarbeitet den Fragebogen mit Ihnen zusammen.',
  stage: {
    item: 'Eis',
  },
  submit: 'Absenden',
  support: 'Absenden fehlgeschlagen. Bitte kontaktieren Sie den Support!',
  submitLoading: 'Daten werden verarbeitet. Bitte haben Sie einen Moment Geduld!',
  switch: {
    no: 'Nein',
    yes: 'Ja',
  },
  uploadMoreFiles: 'Weitere Dateien auswählen',
  uploadPlaceholder:
    'Klicken sie hier um Dateien auszuwählen oder ziehen diese einfach hier hinein.',
};
