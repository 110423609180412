import { StepId } from '@/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import component from './Done.vue';

const config: StepDefinition = {
  component,
  id: StepId.done,
  isFinal: true,
};

export default config;
