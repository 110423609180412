























































































































import { VInput } from 'vuetify/lib';
import { PropType, computed, ref, watch } from '@vue/composition-api';
import GcCard from './primitives/GcCard.vue';
import MultipleChoiceCheckbox from './MultipleChoiceCheckbox.vue';
import GcTextField from './primitives/GcTextField.vue';
import {
  MultipleChoiceOption,
  StepDefinition,
} from '../interfaces/step-definition';
import { useResponsiveBreakpoints } from '../lib/ui-helper/responsive';
import GcCol from './primitives/GcCol.vue';
import GcRow from './primitives/GcRow.vue';
import {
  getOptionDescriptionString,
  getOptionLabelString,
} from '../lib/forms/helper';
import { AssistantContext } from '../config/context';

export default VInput.extend({
  components: {
    GcCard,
    GcCol,
    GcRow,
    GcTextField,
    MultipleChoiceCheckbox,
  },
  props: {
    assistantContext: {
      default: () => ({}),
      type: Object as PropType<AssistantContext>,
    },
    assistantStep: {
      required: true,
      type: Object as PropType<StepDefinition>,
    },
    columns: {
      default: 1,
      type: Number,
    },
    enableCustomOption: {
      default: false,
      type: Boolean,
    },
    fieldName: {
      required: true,
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    options: {
      type: Array as PropType<Array<MultipleChoiceOption>>,
    },
    singleAnswer: {
      default: false,
      type: Boolean,
    },
    verticalOptionLayout: {
      default: false,
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const { isMd } = useResponsiveBreakpoints();

    const filteredOptions = computed(() =>
      (props.options as Array<MultipleChoiceOption>).filter(option => {
        return option.visibilityCondition !== undefined &&
          typeof option.visibilityCondition === 'function'
          ? option.visibilityCondition(props.assistantContext)
          : true;
      }),
    );

    const getCustomValue = () => {
      if (!props.value || props.value.length === 0) {
        return '';
      }
      const customValues = props.value.filter(
        (v: string) =>
          !props.options?.map((o: MultipleChoiceOption) => o.value).includes(v),
      );
      return customValues.length > 0 ? customValues[0] : '';
    };

    const customOption = ref<string>(getCustomValue());
    const customOptionSelected = computed(() => {
      return (
        props.enableCustomOption &&
        customOption.value.length > 0 &&
        props.value.includes(customOption.value)
      );
    });

    watch(props, () => {
      if (
        props.singleAnswer &&
        customOption.value.length > 0 &&
        props.value[0] !== customOption.value
      ) {
        customOption.value = '';
      }
    });

    watch(customOption, (val, oldVal) => {
      if (
        val.length === 0 &&
        props.value.length > 0 &&
        props.options
          .map((o: MultipleChoiceOption) => o.value)
          .includes(props.value[0])
      ) {
        return;
      }
      if (props.singleAnswer) {
        emit('input', val.trim().length > 0 ? [val] : []);
      } else {
        const oldValueIndex = props.value.findIndex(
          (v: string) => v === oldVal.trim(),
        );
        let updatedArray = [...props.value];
        if (oldValueIndex > -1) {
          updatedArray[oldValueIndex] = val.trim();
        } else {
          updatedArray.push(val.trim());
        }
        updatedArray = updatedArray.filter(v => v.trim().length > 0);
        emit('input', updatedArray);
      }
    });

    const selectOption = (id: string) => {
      if (id.length === 0) {
        emit('input', []);
        return;
      }
      const oldValueIndex = props.value.findIndex((v: string) => v === id);
      const updatedArray = [...props.value];
      if (props.singleAnswer) {
        updatedArray.splice(0);
        if (oldValueIndex === -1) updatedArray.push(id);
      } else if (oldValueIndex > -1) {
        updatedArray.splice(oldValueIndex, 1);
      } else {
        updatedArray.push(id);
      }
      emit('input', updatedArray);
    };

    const handleInputFocus = () => {
      if (
        props.singleAnswer &&
        props.value.length > 0 &&
        customOption.value.length === 0
      ) {
        emit('input', []);
      }
    };

    return {
      customOption,
      customOptionSelected,
      filteredOptions,
      getOptionDescriptionString,
      getOptionLabelString,
      handleInputFocus,
      isMd,
      selectOption,
    };
  },
});
