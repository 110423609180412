import axios, { AxiosRequestConfig } from 'axios';
import { Product } from '@shopware-pwa/commons/interfaces/models/content/product/Product';
import { ShopwareSearchParams } from '@shopware-pwa/commons/interfaces/search/SearchCriteria';

export interface GcProductDataExtension {
  showRevocationCheckbox: boolean | null;
  hideCartButton: boolean | null;
  hideProductPrice: boolean | null;
  hideVariantsProductDetail: boolean | null;
  hideVariantsCartOrder: boolean | null;
  translated: {
    topBenefitText: string | null;
    customProductPriceText: string | null;
    extDescription: string | null;
  };
}

export type accessProtectionOptions = 'app' | 'key_switch' | 'pin' | 'rfid';

export type chargingPowersWatt = 3700 | 5500 | 7400 | 11000 | 22000 | 50000; // '3,7 kW' | '5,5 kW' | '7,4 kW' | '11 kW' | '22 kW'

export type chargingConnectorTypes = 'type_2_cable' | 'type_2_socket';

export interface GcProductWallboxDataExtension {
  manufacturerIdentifier?: string;
  accessProtectionOptions?: accessProtectionOptions[];
  billable?: boolean;
  billingSolution?: boolean;
  cableLengthMeter?: number;
  chargingPowersWatt?: chargingPowersWatt[];
  chargingPowerConfigurable?: boolean;
  chargingConnectorTypes?: chargingConnectorTypes[];
  electricCurrentsAmpere?: number[];
  smartphoneControllable?: boolean;
  voltagesVolt?: number[];
  noOfPhases?: number;
  electricProtectionDevices?: string[];
  protocols?: string[];
  networkConnectors?: string[];
  protectionClasses?: string[];
  noOfChargingPoints?: number;
  installationOptions?: string[];
  displayTypes?: string[];
  energyMeters?: string[];
  loadManagement?: boolean;
  pvCombinable?: boolean;
  outdoorReady?: boolean;
  climateNeutral?: boolean;
}

export interface GcSwProduct extends Omit<Product, 'extensions'> {
  extensions: {
    gcProductData?: GcProductDataExtension[];
    gcProductWallboxData?: GcProductWallboxDataExtension[];
  };
}

const authorization: { [key: string]: string } =
  process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true'
    ? { authorization: `Basic ${process.env.VUE_APP_SHOP_BASIC_AUTH}` }
    : {};

/**
 * General load product data function
 */
const loadProducts = (
  data: ShopwareSearchParams,
  config: AxiosRequestConfig,
): Promise<GcSwProduct[]> =>
  new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_SHOP_URL}/store-api/product`, data, config)
      .then(response => {
        if (response.status !== 200) {
          reject();
          return;
        }

        if (
          response.data.total < 1 ||
          !response.data.elements ||
          response.data.elements.length < 1
        ) {
          resolve([]);
          return;
        }

        resolve(response.data.elements as GcSwProduct[]);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        reject();
      });
  });

/**
 * Load product data for all products matching the given ids
 */
export const loadProductsByIds = (ids: string[]): Promise<GcSwProduct[]> =>
  loadProducts(
    {
      associations: {
        options: {},
      },
      ids,
    },
    {
      headers: {
        'sw-access-key': process.env.VUE_APP_SHOP_API_KEY,
        ...authorization,
      },
    },
  );

/**
 * Load product data for all products matching the given filter
 */
export const loadProductsByFilter = (
  filterWithAssociations: NonNullable<
    Pick<ShopwareSearchParams, 'filter' | 'associations'>
  >,
): Promise<GcSwProduct[]> =>
  loadProducts(filterWithAssociations, {
    headers: {
      'sw-access-key': process.env.VUE_APP_SHOP_API_KEY,
      ...authorization,
    },
  });

/**
 * Load product tile by product id
 */
export const loadProductTileById = async (id: string): Promise<string> => {
  // If shadow DOM is not supported require content with inlined styles as fallback
  const attachShadow = !HTMLElement.prototype.attachShadow
    ? '&inline=true'
    : '';
  const productTileUrl = `${process.env.VUE_APP_SHOP_HOST}/gc/product-tile/content?tileSize=standard&productLinkTarget=blank&productId=${id}${attachShadow}`;

  try {
    const response = await axios.get(`${productTileUrl}`, {
      headers: {
        'sw-access-key': process.env.VUE_APP_SHOP_API_KEY,
        ...authorization,
      },
    });

    if (response.status !== 200) {
      // eslint-disable-next-line
      console.error(response);
      return '';
    }

    return response.data && response.data.length > 0 ? response.data : '';
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return '';
  }
};
