





































import GcForm from '../../components/primitives/GcForm.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import OverviewListItem from '../../components/OverviewListItem.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { stepComponentProps } from '@/lib/steps/helper';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import md from '../../lib/formatter/markdown';
import { getFormattedStepValues } from '@/lib/formatter/context';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepId } from '@/consts/assistant-steps';
import FormField from '../../components/FormField.vue';
import completedSteps from '../../lib/completed-steps';
import { submit } from '../../lib/submit/submit';
import { sendGAEvent } from '@/lib/analytics/ga';
import i18n from '@/plugins/i18n';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
    OverviewListItem,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const { onPrev } = useBaseTransitions(emit);

    const steps = [
      StepId.isAlreadyStawagCustomer,
      StepId.counterNumber,
      StepId.customerDataCollection,
      StepId.carAdmission,
      StepId.rangeEstimation,
      StepId.vehicleRegistrationDocument,
      StepId.summary,
      StepId.done,
    ];

    const overviewItems = computed(() => {
      return steps
        .map(stepId => {
          const stepDef = props.steps?.find(step => step.id === stepId);
          return {
            content: stepDef
              ? getFormattedStepValues(props.assistantContext, stepDef)
              : '',
            stepId,
          };
        })
        .filter(item => item.content.trim().length > 0);
    });

    const error = ref(false);
    const submitting = ref(false);
    const submitError = ref<boolean | string>(false);

    async function onNext() {
      if (
        process.env.VUE_APP_GA_EVENT_ACTION &&
        process.env.VUE_APP_GA_EVENT_CATEGORY &&
        process.env.VUE_APP_GA_EVENT_LABEL
      ) {
        sendGAEvent({
          action: process.env.VUE_APP_GA_EVENT_ACTION,
          category: process.env.VUE_APP_GA_EVENT_CATEGORY,
          label: process.env.VUE_APP_GA_EVENT_LABEL,
        });
      }

      submitError.value = false;
      submitting.value = true;

      try {
        await submit(props.steps as StepDefinition[], props.assistantContext);
        const { onNext } = useBaseTransitions(emit);
        onNext();
      } catch (e) {
        submitError.value = e.message || i18n.t('message.error').toString();
        error.value = true;
      } finally {
        submitting.value = false;
      }
    }

    function editStep(stepId: string) {
      emit('transition', {
        event: `BACK_TO_${stepId.toUpperCase()}`,
      });
    }

    return {
      completedSteps,
      editStep,
      error,
      md,
      onNext,
      onPrev,
      overviewItems,
      submitError,
      submitting,
    };
  },
});
