export default {
  Summary: {
    label: 'Übersicht Ihrer Eingaben',
    submitButtonText: 'Absenden',
    title: 'Zusammenfassung',
  },
  carAdmission: {
    formFields: {
      admissionedPerson: {
        label: 'Auf wen ist das Elektrofahrzeug zugelassen?',
        options: {
          employer: {
            label: 'Meinen Arbeitgeber',
          },
          mySelf: {
            label: 'Mich selbst',
          },
          other: {
            label: 'Jemand anderen (gemietet/geleast)',
          },
          otherFromOwnHousehold: {
            label: 'Jemanden aus dem Haushalt',
          },
        },
      },
    },
    title: 'Fahrzeugzulassung',
  },
  counterNumber: {
    formFields: {
      counterNumber: {
        label: 'Zählernummer:',
      },
    },
    title: 'Zählernummer',
  },
  customerDataCollection: {
    formFields: {
      city: {
        label: 'Stadt:',
      },
      contractHolder: {
        label: 'Name des Vertraginhabers',
      },
      email: {
        label: 'E-Mail:',
      },
      houseNumber: {
        label: 'Hausnummer:',
      },
      lastname: {
        label: 'Nachname:',
      },
      prename: {
        label: 'Vorname:',
      },
      street: {
        label: 'Straße:',
      },
      zipcode: {
        label: 'Postleitzahl:',
      },
    },
    label: 'Kontaktdaten',
    title: 'Kontaktdaten',
  },
  done: {
    label: 'Übersicht Ihrer Eingaben',
    title: 'Fertig',
  },
  isAlreadyStawagCustomer: {
    formFields: {
      contractId: {
        label: 'Vertragsnummer:',
      },
      isAlreadyStawagCustomer: {
        label: 'Bereits STAWAG Kunde?',
        options: {
          no: {
            label: 'Nein',
          },
          yes: {
            label: 'Ja',
          },
        },
      },
    },
    title: 'Einführung',
  },
  location: {
    formFields: {},
    label: '',
    title: '',
  },
  rangeEstimation: {
    formFields: {
      rangeEstimation: {
        label: 'Wie viele Kilometer fahren Sie jährlich?',
        options: {
          less: {
            label: 'Weniger als 10.000km/Jahr',
          },
          more: {
            label: 'Mehr als 10.000km/Jahr',
          },
        },
      },
    },
    title: 'Reichweite',
  },
  start: {
    buttonText: 'Ich möchte einen Tarif buchen!',
    description:
      'Um Ihnen ein auf Sie zugeschnittenes Angebot unterbreiten zu können, benötigen wir einige Informationen von Ihnen.',
    label: 'Vielen Dank für Ihr Interesse an unseren Autostrom-Tarifen.',
    title: 'Start',
  },
  summary: {
    label: 'Übersicht Ihrer Eingaben',
    productPreview: 'Produktvorschläge',
    submitButtonText: 'Absenden',
    text:
      'Bitte prüfen Sie hier Ihre Angaben. Wir freuen uns auf Ihre Anfrage.',
    title: 'Zusammenfassung',
  },
  vehicleRegistrationDocument: {
    formFields: {
      driverLicense: {
        label: 'Fahrzeugschein hochladen:',
      },
    },
    title: 'Dokumente',
  },
};
