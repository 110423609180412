import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import { getTenantConfig } from '../lib/tenants';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: {
      add: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'plus'],
        },
      },
      calendar: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'calendar-alt'],
        },
      },
      calendarRange: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'calendar-week'],
        },
      },
      car: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'car'],
        },
      },
      clear: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times'],
        },
      },
      clock: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'clock'],
        },
      },
      edit: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'pencil-alt'],
        },
      },
      remove: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'minus'],
        },
      },
      search: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'search'],
        },
      },
    },
  },
  lang: {
    current: 'de',
    locales: { de },
  },
  theme: {
    options: { customProperties: true },
    themes: { light: getTenantConfig().styles.colors },
  },
});
