import { StepId } from '@/consts/assistant-steps';
import {
  FormUploadFieldConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import component from './VehicleRegistrationDocument.vue';
import { FormFieldType } from '@/consts/input-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        accept: 'image/*,.pdf',
        multiple: true,
        size: 10 * 1024 * 1024, // 10 MiB
      } as FormUploadFieldConfig,
      name: 'driverLicense',
      outputFormatter: files => {
        return files && files.length > 0
          ? files.map((file: File) => `${file.name}`).join('\n')
          : '';
      },
      required: false,
      type: FormFieldType.DropZone,
    },
  ],
  id: StepId.vehicleRegistrationDocument,
};

export default config;
