export enum StepId {
  isAlreadyStawagCustomer = 'isAlreadyStawagCustomer',
  customerDataCollection = 'customerDataCollection',
  counterNumber = 'counterNumber',
  carAdmission = 'carAdmission',
  rangeEstimation = 'rangeEstimation',
  vehicleRegistrationDocument = 'vehicleRegistrationDocument',
  summary = 'summary',
  done = 'done',
}

export enum StepGroupId {
  LOCATIONS = 'locations',
}
