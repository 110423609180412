import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import { FormFieldType } from '../../consts/input-types';
import component from './RangeEstimation.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        options: [
          {
            value: 'less',
          },
          {
            value: 'more',
          },
        ],
        singleAnswer: true,
      },
      name: 'rangeEstimation',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.rangeEstimation,
};

export default config;
