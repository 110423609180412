
































import GcForm from '../../components/primitives/GcForm.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import completedSteps from '@/lib/completed-steps';
import { defineComponent, ref } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import FormField from '../../components/FormField.vue';
export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const formData = getStepContext(props.assistantContext, props.step!);
    const { onPrev } = useBaseTransitions(emit);

    function onNext() {
      const { onNext } = useBaseTransitions(emit);
      if (
        props.assistantContext.isAlreadyStawagCustomer
          .isAlreadyStawagCustomer[0] === 'yes'
      ) {
        onNext();
        return;
      }

      props.assistantContext.isAlreadyStawagCustomer.contractId = '';
      onNext();
    }

    return {
      completedSteps,
      formData,
      onNext,
      onPrev,
    };
  },
});
