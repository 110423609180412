import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import { FormFieldType } from '../../consts/input-types';
import { ValidationType } from '@/consts/validation-types';
import component from './CustomerDataCollection.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'email',
      required: true,
      type: FormFieldType.TextField,
      validation: [ValidationType.email],
    },
    {
      name: 'prename',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'lastname',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'street',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'houseNumber',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'city',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'zipcode',
      required: true,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.customerDataCollection,
};

export default config;
