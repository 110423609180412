import { reactive, watch } from '@vue/composition-api';
import { StepId } from '../consts/assistant-steps';

const COMPLETED_STEPS_KEY = 'assistent-completed-steps-key';

// use local storage if env variable is set to "true"
const useLocalStorage = process.env.VUE_APP_USE_LOCALSTORAGE == 'true';

const stepsMap = {};
for (const id in StepId) {
  stepsMap[StepId[id]] = false;
}

const reactiveStepsMap = reactive(stepsMap);

if(useLocalStorage) {
  watch(reactiveStepsMap, reactiveStepsMap => {
    localStorage.setItem(COMPLETED_STEPS_KEY, JSON.stringify(reactiveStepsMap));
  });
}

export function restoreCompletedSteps() {
  if (useLocalStorage && localStorage) {
    const stepsStr = localStorage.getItem(COMPLETED_STEPS_KEY);
    if (stepsStr) {
      const storedSteps = JSON.parse(stepsStr);
      for (const key in storedSteps) {
        reactiveStepsMap[key] = storedSteps[key];
      }
    }
  }
}

export function removeCompletedSteps() {
  if(useLocalStorage) {
    localStorage.removeItem(COMPLETED_STEPS_KEY);
  }
}

export default reactiveStepsMap;
