import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import { FormFieldType } from '../../consts/input-types';
import component from './CounterNumber.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'counterNumber',
      required: true,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.counterNumber,
};

export default config;
